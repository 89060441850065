import React, { Component } from "react"
import FormMaker from "../../components/formMaker"
import { connect } from "react-redux"
import { storeCurrentRequestId } from "../../redux/actions"
import { Steps } from "intro.js-react"
import IntroWrapper from '../../components/client-only-wrapper';

export class NewPromotion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: {
        showProgress: true,
        dontShowAgain: true,
        showBullets: false,
        scrollToElement: false,
      },
      showUsers: true,
      initialStep: 0,
      steps: [
        {
          element: "#promotion_objective",
          title: "Obective",
          intro: "Example: For our members to buy more immufort",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: "#promotion_reason",
          title: "Reason",
          intro:
            "Example: We have a lot of stock of immufort and we don't want to be left short dated stock",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: ".productList",
          title: "Products List",
          intro:
            "Be sure to select all the products you require and fill in current stock and best before dates",
          position: "top",
          tooltipClass: "intro-title",
        },
        // {
        //   element: "#promotion_details",
        //   title: "Reason",
        //   intro:
        //     "This is the second longtext component in the dom. How can I get to the reason longtext component below.",
        //   position: "left",
        //   tooltipClass: "intro-title",
        // },
        {
          element: ".submitLater",
          title: "NOTE:",
          intro:
            "You can save what you have done so far and come back to it later Just remember that for it to be sent to head office you have to select the 'submit form' button",
          position: "top",
          tooltipClass: "intro-title",
        },
      ],
    }
  }

  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }))
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }))
  }
  render() {
    const {
      stepsEnabled,
      steps,
      initialStep,
      options,
      scrollToElement,
    } = this.state
    console.log(this.props)
    return (
      <div>
        <IntroWrapper>
          <Steps
            scrollToElement={scrollToElement}
            options={options}
            enabled={stepsEnabled}
            steps={steps}
            initialStep={initialStep}
            onExit={this.onExit}
          />
        </IntroWrapper>
        <button
          className="fixed bottom-20 right-20 h-28 w-28 bg-brand-blue rounded-full text-white font-bold flex items-center justify-center shadow-lg z-50 font-medium"
          onClick={this.toggleSteps}
        >
          <span className="font-medium">Need Help?</span>
        </button>
        <FormMaker
          formId="602db3f7ba5f15001bb5300f"
          formType="product-promotions"
          {...this.props}
          objective="objective"
          productList="productList"
          id={`${"promotion_objective"} ${"promotion_reason"}`}
          submitLater="submitLater"
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  current_request_id: state.current_request_id,
  ...props,
})

function mapDispatchToProps(dispatch) {
  return {
    storeCurrentRequestId: id => dispatch(storeCurrentRequestId(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPromotion)
